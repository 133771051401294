<template>
  <span class='HeadlineDetails galleryCount'>{{ galleryCountString }}</span>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'gallery-top-bar',
    computed: {
      galleryCountString() {
        if (!this.galleryItems) return '';
        return `(${this.galleryItems.length} stk.)`;
      },
      ...mapState('DownloadStore', ['galleryItems']),
    },
  };
</script>
